import React, { useState, useEffect, useRef } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import axios from 'axios';

import logo from "./TeledentalSolutionLogo13.png";

import Dropdown from "react-bootstrap/Dropdown";
import InputGroup from "react-bootstrap/InputGroup";
import FormCntrl from "react-bootstrap/FormControl";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
// import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Bootbox from "bootbox-react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import {AmplifySignOut} from "@aws-amplify/ui-react";

import "./App.css";
import { API, Storage, Auth } from "aws-amplify";

import { updateKSK12Student as updateStudentMutation } from "./graphql/mutations";

import { useHistory } from "react-router-dom";
// Language translation imports.
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import QrReader from "react-web-qr-reader";

const DemoBottomImg =
  "https://screeningdemoimages.s3.amazonaws.com/BottomDemo.JPG";
const DemoTopImg = "https://screeningdemoimages.s3.amazonaws.com/TopDemo.jpg";
const DemoFrontTeethImg =
  "https://screeningdemoimages.s3.amazonaws.com/FrontTeethDemo.JPG";
const DemoOptOutLetter =
  "https://screeningdemoimages.s3.amazonaws.com/LetterDemo.png";

let selectedFrontImage;
let selectedTopImage;
let selectedBottomImage;
let selectedOptoutLetter;

const initialFormState = {
  id: "",
  studentId: "",
  guardianEmail: "",
  guardianEmail2: "",
  firstName: "",
  gender: "",
  district: "",
  school: "",
  classTeacher: "",
  student: "",
  grade: "",
  frontImage: "",
  frontImageTemp:"",
  topImage: "",
  topImageTemp: "",
  bottomImage: "",
  bottomImageTemp: "",
  location: "School",
  haveDentalInsurance: "Yes",
  okToReceiveMedicaidInfo: "No",
  evalStatus: "New",
  optout: null,
  dentalPain: "No",
  optoutReason: "NA",
  screener: "",
  screeningDate: new Date(),
  nurseEmail:"",
  okToSubmit: true
};

let schoolList = [];
let classTeachers = [];
let listOfStudent = [];
let gradelist = [];

let type = null;

/** This will be used to create set of options that user will see */
let options = null;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const AutoCollectionApp = () => {
  const [formData, setFormData] = useState(initialFormState);
  const [lang, setLang] = React.useState("en");
  //Access screener name to update automatically
  const [screener, setscreenerName] = useState("");
  const [students, setStudents] = useState([]);
  const [allStudents, setAllStudents] = useState([]);
  const [studentsByFilter, setStudentsByFilter] = useState([]);
  const [gradeByTeacher, setGradeByTeacher] = useState([]);
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [confirmSubmitModel, setConfirmSubmitModel] = React.useState(false);
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(true);
  const [isResetStudent, setResetStudent] = useState(false);
  const [show, setShow] = useState(false);
  const [optOutButton, setOptOutButton] = useState(false);
  const [deleteOptOut, setDeleteOptOut] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [allStudentData, setAllStudentData] = useState([]);
  const [showOptLTR, setShowOptLTR] = useState(false);
  const toggle = () => setShow(!show);
  const inputFile = useRef(null);
  // QR CODE
  const [selected, setSelected] = useState("environment");
  const [loadingScan, setLoadingScan] = useState(false);
  const [studentDataSubmitted, setStudentDataSubmitted] = useState(false);
  
  const handleScan = async (scanData) => {
    setLoadingScan(true);
    console.log(`loaded data data`, scanData);
    if (scanData && scanData !== "") {
      console.log(`loaded >>>`, scanData.data);
      fillTheForm(scanData.data);
      let studentiddata = (scanData.data.split(";"))[3];
    
    
  //    setStartScan(false);
      setLoadingScan(false);
      // setPrecScan(scanData);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
 

  async function fillTheForm(dataString) {
    dataString = dataString.split(";");
    console.log(dataString);
     let qrCodeFormState = {
      school: dataString[0],
      grade: dataString[1],
      classTeacher: dataString[2],
      studentId: dataString[3],
    };
    populateStudentDataList(qrCodeFormState);    
    
  }
// QR CODE

  if (type) {
    options = type.map((el) => <option key={el}>{el}</option>);
  }
  let history = useHistory();

  useEffect(() => {
    signIn();
  }, [
    screener,
  ]); /* retrieve all students from DynamoDB using graphql API interace */

  const handleOpen = () => {
    setOpen(!open);
  };

 const handleConfirmSubmitModelClose = () => {
    setFormData(initialFormState);
    const labels = document.getElementsByClassName("image-input-label");
    for (let i = 0; i < labels.length; i++) {
      const element = labels[i];
      element.style.background = "none";
      element.innerHTML = "+";
    }
    setConfirmSubmitModel(!confirmSubmitModel);
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      // flexGrow: 1,
    },
    flexToolbar: {
      padding: "0 20px",
      display: "flex",
      justifyContent: "space-between",
    },
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

  const classes = useStyles();

  const generateImageFileName = (fileName) => {
    return `${formData.id}-${formData.studentId.slice(1, 5)}-${fileName}`;
  };

  async function onChangetopimage(e) {
    if (!e.target.files[0]) return;
    var reader = new FileReader();

    reader.onload = function (e) {
      const el = document.getElementById("top");
      el.style.display = "inline-flex";
      el.style.background = `url(${e.target.result})`;
      el.style.backgroundRepeat = "no-repeat";
      el.style.backgroundSize = "cover";
      el.style.width = "149.7px";
      el.style.height = "142px";
      el.innerHTML = "";
    };

    selectedTopImage = e.target.files[0];
    reader.readAsDataURL(selectedTopImage);
    setFormData({
      ...formData,
      topImage: generateImageFileName("top"),
    });
  }

  async function onChangebottomimage(e) {
    if (!e.target.files[0]) return;
    var reader = new FileReader();

    reader.onload = function (e) {
      const el = document.getElementById("bottom");
      el.style.display = "inline-flex";
      el.style.background = `url(${e.target.result})`;
      el.style.backgroundRepeat = "no-repeat";
      el.style.backgroundSize = "cover";
      el.style.width = "149.7px";
      el.style.height = "142px";
      el.innerHTML = "";
    };

    //  const file = e.target.files[0];
    selectedBottomImage = e.target.files[0];
    reader.readAsDataURL(selectedBottomImage);
    setFormData({
      ...formData,
      bottomImage: generateImageFileName("bottom"),
    });
  }

  async function onChangeFrontTeethimage(e) {
    if (!e.target.files[0]) return;
    var reader = new FileReader();

    reader.onload = function (e) {
      const el = document.getElementById("front-teeth");
      el.style.display = "inline-flex";
      el.style.background = `url(${e.target.result})`;
      el.style.backgroundRepeat = "no-repeat";
      el.style.backgroundSize = "cover";
      el.style.width = "149.7px";
      el.style.height = "142px";
      el.innerHTML = "";
    };

    // const file = e.target.files[0];
    selectedFrontImage = e.target.files[0];
    reader.readAsDataURL(selectedFrontImage);
    setFormData({
      ...formData,
      frontImage: generateImageFileName("front"),
    });
  }

  async function onChangeOptoutLetter(e) {
    setShowPrompt(false);
    setDeleteOptOut(false);
  
    if (!e.target.files[0]) return;
    const optoutLetterName = e.target.files[0].name;
    var ext = e.target.files[0].name.split(".").pop();
    if (ext == "png" || ext == "jpeg" || ext == "jpg") {
      setOptOutButton(true);
      var reader = new FileReader();
      reader.onload = function (e) {
        const el = document.getElementById("optout-letter");
        el.style.display = "inline-flex";
        el.style.background = `url(${e.target.result})`;
        el.style.backgroundRepeat = "no-repeat";
        el.style.backgroundSize = "cover";
        el.style.width = "149.7px";
        el.style.height = "142px";
        el.style.color = "#000000";
        el.innerHTML = "";
      };

      selectedOptoutLetter = e.target.files[0];
      reader.readAsDataURL(selectedOptoutLetter);
      const dynamicLetterName = generateImageFileName("optout");
      setFormData({
        ...formData,
        optout: dynamicLetterName,
        topImage: "",
        bottomImage: "",
        frontImage: "",
      });
      renderImage("front-teeth", "");
      renderImage("top", "");
      renderImage("bottom", "");
    } else {
      alert("Only PNG, JPG and JPEG extentions are allowed!");
    }
  }

  //function to autologin
  async function signIn() {
    try {
      //await Auth.signIn(formInputState.username, formInputState.password);
      await Auth.signIn("BGCT", "Password1234");
      // await Auth.signIn("testscreener", "Password1234");
      /* Once the user successfully signs in, update the form state to show the signed in state */
      //formState = "signedIn";
      console.log("Auto logged in successfully");
      func();
    } catch (err) { console.log({ err }); }
  }

  //Set the login user as the Student.screener
  const func = async () => {
    let user = await Auth.currentAuthenticatedUser();
    setscreenerName(user.username); //all attributes exist in the attributes field
    await fetchAllStudentsAPI(user.username);
  };

  // Fetch all studen api--
  async function fetchAllStudentsAPI(paramScreener) {
    let studentsFromAPI;
    initialFormState.district = "";
    initialFormState.school = "";
    const FetchAllStudent = "https://mi8oescfc4.execute-api.us-east-1.amazonaws.com/api/getstudentbynurseid";
    const FetchAllStudentPayload = {"nurseId": paramScreener};
   
    await axios.post(FetchAllStudent,FetchAllStudentPayload).then((response)=> {
      setAllStudentData(response.data);
      studentsFromAPI = response.data;
    });
    studentsFromAPI = studentsFromAPI.filter((s) => {
      return s.nurseId === paramScreener;
    });

    studentsFromAPI.map(async (student) => {
      if (student.leftimage) {
        student.leftimageTemp = student.leftimage
        const image = await Storage.get(student.leftimage);
        student.leftimage = image;
      }
      if (student.rightimage) {
        const image = await Storage.get(student.rightimage);
        student.rightimage = image;
      }
      if (student.topImage) {
        student.topImageTemp = student.topImage;
        const image = await Storage.get(student.topImage);
        student.topImage = image;
      }
      if (student.bottomImage) {
        student.bottomImageTemp = student.bottomImage;
        const image = await Storage.get(student.bottomImage);
        student.bottomImage = image;
      }
      if (student.nonsmilingface) {
        const image = await Storage.get(student.nonsmilingface);
        student.nonsmilingface = image;
      }
      if (student.frontImage) {
        student.frontImageTemp = student.frontImage;
        const image = await Storage.get(student.frontImage);
        student.frontImage = image;
      }
      if (student.optout) {
        const optoutpdf = await Storage.get(student.optout);
        student.optout = optoutpdf;
      }
      return student;
    })
  
    let sortedArr = studentsFromAPI.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );

    schoolList = [];
    sortedArr.forEach((stdnt) => {
      schoolList.push({title: stdnt.school});
    });
    // Prepopulate  district of first student.
    if (sortedArr[0]) {
      setFormData({
        ...formData,
        district: sortedArr[0].district,
      });
    }

    // getting unique schools list:
    schoolList = [
      ...new Map(schoolList.map((item) => [item["title"], item])).values(),
    ];

    sortedArr = sortedArr.filter((s) => {
      return s.district === sortedArr[0].district;
    });

    setStudents(sortedArr);
    console.log("page loaded true");
    setIsPageLoaded(true);
    
  }

  //  Pre populate School Name
  function populateGradeBySchoolname(schoolName) {
    if (!schoolName) {
      resetStudent();
    } else {
      gradelist = [];
      classTeachers = [];
      const filtedGrade = allStudents.filter((stdData) => {
        return stdData.school === schoolName.title;
      });
    
      filtedGrade.forEach((std) => {
        gradelist.push({ title: std.grade });
      });
      // getting unique grades list:
      gradelist = [
        ...new Map(gradelist.map((item) => [item["title"], item])).values(),
      ];
      
      setFormData(initialFormState);
      setFormData({
        ...initialFormState,
        school: schoolName.title
      });
      setGradeByTeacher(gradelist.sort((a, b) => parseInt(a.title) - parseInt(b.title)));
      renderImage("front-teeth", "");
      renderImage("top", "");
      renderImage("bottom", "");
      renderImage("optout-letter", "");      
      setStudents(filtedGrade);
    }
   
  }
  //  Pre populate student data from student id.
  async function populateStudentDataList(scannedData) {

      const filtedStd = allStudentData.filter((stdData) => {
        return  stdData.school === scannedData.school 
          && stdData.grade === scannedData.grade
          && stdData.classTeacher === scannedData.classTeacher
          && stdData.studentId === scannedData.studentId;
      });

      console.log("filtedStd", filtedStd);
      if (filtedStd[0]) {
        // setting undefined values.
        filtedStd[0].frontImage = (filtedStd[0].frontImage === undefined) ? "" : filtedStd[0].frontImage;
        filtedStd[0].topImage = (filtedStd[0].topImage === undefined) ? "" : filtedStd[0].topImage;
        filtedStd[0].bottomImage = (filtedStd[0].bottomImage === undefined) ? "" : filtedStd[0].bottomImage;
        filtedStd[0].optout = (filtedStd[0].optout === undefined) ? null : filtedStd[0].optout;
        filtedStd[0].nurseName = null;
        filtedStd[0].screeningDate = null;
        filtedStd[0].guardianEmail = filtedStd[0].guardianEmail;
        filtedStd[0].guardianEmail2 = filtedStd[0].guardianEmail2;
        filtedStd[0].untreatedDecay = null;
        filtedStd[0].treatedDecay = null;
        filtedStd[0].sealantsPresent = null;
        filtedStd[0].treatmentRecommendationCode = null;
        filtedStd[0].cannotEvaluate = null;
        filtedStd[0].evalcomments = null;
        filtedStd[0].evaluator = null;
        filtedStd[0].evalType = null;
        filtedStd[0].evalDate = null;
        filtedStd[0].dentalPain = (filtedStd[0].dentalPain === "Yes") ? "Yes" : "No";
        setResetStudent(true);
        // get 3 chars from firstname
        filtedStd[0].firstName = filtedStd[0].firstName.slice(0, 3);
        setFormData(filtedStd[0]);
        if(filtedStd[0].frontImage.includes("https")){
          renderImage("front-teeth", filtedStd[0].frontImage);
        }else{
          if(filtedStd[0].frontImage){
            const image = await Storage.get(filtedStd[0].frontImage);
            renderImage("front-teeth", image);
          }else{
            renderImage("front-teeth", "");
          }           
        }

        if(filtedStd[0].topImage.includes("https")){
          renderImage("top", filtedStd[0].topImage);
        }else{
          if(filtedStd[0].topImage){
            const image = await Storage.get(filtedStd[0].topImage);
            renderImage("top", image);
          }else{
            renderImage("top", "");
          }      
        }

        if(filtedStd[0].bottomImage.includes("https")){
          renderImage("bottom", filtedStd[0].bottomImage);
        }else{
          
          // const image = await Storage.get(filtedStd[0].bottomImage);
          // renderImage("bottom", image);
          if(filtedStd[0].bottomImage){
            const image = await Storage.get(filtedStd[0].bottomImage);
            renderImage("bottom", image);
          }else{
            renderImage("bottom", "");
          }
        }
                
        renderImage("optout-letter", filtedStd[0].optout);
        if (filtedStd[0].evalStatus == "Completed") {
          toggle();
          // alert("Student cannot be edited as the screening evaluation has been completed for this student");
        }
      }

      if(filtedStd.length == 0) {
        alert("Student "+ scannedData.studentId + " Not Found!"); 
      } else {
        setTimeout(() => {
          alert(scannedData.studentId + " "+ "Please take 3 dental pictures and submit");
          
        }, 500);
      }
  }

  function renderImage(position, imgUrl, innerHTML) {
    if (imgUrl) {
      const el = document.getElementById(position);
      el.style.display = "inline-flex";
      el.style.background = `url(${imgUrl})`;
      el.style.backgroundRepeat = "no-repeat";
      el.style.backgroundSize = "cover";
      el.style.width = "149.7px";
      el.style.height = "142px";
      el.style.color = "#000";
      el.innerHTML = "";
      if (position === "optout-letter") {
        setOptOutButton(true);
        // el.innerHTML = imgUrl.split("?")[0] + ".pdf";
      }
    } else {
      const el = document.getElementById(position);
      if(el) {
        el.style.display = "inline-flex";
        el.style.background = "";
        el.style.backgroundRepeat = "";
        el.style.backgroundSize = "";
        let innerHTML = "";
        if (position === "top") {
          innerHTML = "+ Click Here to Add Top Teeth Photo";
        }
        if (position === "bottom") {
          innerHTML = "+ Click Here to Add Bottom Teeth Photo";
        }
        if (position === "front-teeth") {
          innerHTML = "+ Click Here to Add Front Teeth Photo";
        }
        if (position === "optout-letter") {
          setOptOutButton(false);
          innerHTML = "+ Click Here to upload image of Dentist optout letter";
        }
        el.innerHTML = innerHTML;
      }
    }
  }

  function resetStudent() {
    setStudents(allStudents);
    setFormData(initialFormState);
    setResetStudent(false);
  }

  function resetStudentOnSubmit() {
    let tempFormData = formData;
    tempFormData.studentId = "";
    tempFormData.firstName = "";
    tempFormData.gender = "";
    tempFormData.guardianEmail = "";
    tempFormData.guardianEmail2 = "";
    tempFormData.dentalPain = "No";
    tempFormData.topImage = "";
    tempFormData.frontImage = "";
    tempFormData.bottomImage = "";
    renderImage("front-teeth", "");
    renderImage("top", "");
    renderImage("bottom", "");
    renderImage("optout-letter", "");  

    setStudents(allStudents);
    setFormData(tempFormData);
    setResetStudent(false);
    //setLoadingScan(true);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoaded(false);
    // if (formData.optoutReason !== "NA") {
    //   formData.optout = "Yes";
    // }
    formData.screener = screener;    
    updateKSK12Student(e);
    
    // setFormData(initialFormState);
  }

  // Final update function.
  async function updateKSK12Student(e) {
    e.preventDefault();
    if (!formData.studentId || !formData.gender) return;
    delete formData.createdAt;
    delete formData.updatedAt;
    delete formData.screener;
    delete formData.__typename;

    if (deleteOptOut) {
      formData.optout = null;
      selectedOptoutLetter = null; 
    } 

    if (selectedOptoutLetter) {
      formData.evalStatus = "Optout";
    } else {
      formData.evalStatus = "Screened";
    }
    // Update students data in temp students list.
    const updateStudent = allStudents.forEach(student => {
      if(student.studentId === formData.studentId) {
        student.dentalPain = formData.dentalPain;
        student.bottomImage = formData.bottomImage;
        student.frontImage = formData.frontImage;
        student.topImage = formData.topImage;
      }
    });
    setStudents(updateStudent);
    try {
      if (selectedFrontImage && formData.frontImage) {
        await Storage.put(generateImageFileName("front"), selectedFrontImage);
      }
      else{
        formData.frontImage = formData.frontImageTemp;        
      }
      if (selectedTopImage && formData.topImage) {
        await Storage.put(generateImageFileName("top"), selectedTopImage);
      }
      else{
        formData.topImage = formData.topImageTemp;
      }
      if (selectedBottomImage && formData.bottomImage) {
        await Storage.put(generateImageFileName("bottom"), selectedBottomImage);
      }
      else{
        formData.bottomImage = formData.bottomImageTemp;
      }
      if (selectedOptoutLetter) {
        await Storage.put(
          generateImageFileName("optout"),
          selectedOptoutLetter
        );
      }
    } catch (e) {
      console.log("Error:", e);
    }

    delete formData.bottomImageTemp;
    delete formData.frontImageTemp;
    delete formData.topImageTemp;

    

    let result = await API.graphql({
      query: updateStudentMutation,
      variables: { input: formData },
    });

    
    alert(`${formData.studentId} has been submitted`);
    setStudentDataSubmitted(true);
    setIsLoaded(true);
    
    if(result) {
      resetStudentOnSubmit();      
    }
    window.scrollTo(0, 0);
  }

  // Show initial welcome popup message (only once for login).
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    
    if (sessionStorage && sessionStorage.getItem("hideInfo") !== "yes") {
      sessionStorage.setItem("hideInfo", "yes");
      // alert('Welcome to the Dental Screening program 2021. Your participation in these screenings allows us to potentially receive funding from the state for the health of our children. Please note that this screening does not sign your student up for any dental care. Thank you for your participation.`);
      // alert(
      //  "Bienvenido al programa de Evaluación Dental 2021. Su participación en estas evaluaciones nos permite recibir fondos del estado para la salud de nuestros niños. Tenga en cuenta que esta evaluación no inscribe a su estudiante para recibir atención dental. Gracias por su participación."
      // );
    }
  });
  

  const DisableImgFields = (data) => {
    if (!data.studentId) {
      return true;
    } else if (data.evalStatus === "Completed") {
      return true;
    } else if (formData.optout !== null) {
      return true;
    } else {
      return false;
    }
  };

  const DisableSubmitButton = (data) => {
    if (
      !(
        data.district &&
        data.school &&
        data.studentId &&
        data.firstName &&
        data.grade &&
        data.gender &&
        (data.optout || (data.frontImage && data.topImage && data.bottomImage))
      )
    ) {
      return true;
    } else if (data.evalStatus === "Completed") {
      return true;
    } else {
      return false;
    }
  };

  const populateTeacherDataByGrade = (grade) => {
    if (!grade) {
      resetStudent();
    } else {
      setFormData(initialFormState);
      setFormData({
        ...initialFormState,
        school: formData.school,
        grade: grade.title,
      });

      var teacherList = allStudents.filter((student) => {
        return student.grade === grade.title && student.school === formData.school;
      });
      classTeachers = [];
      listOfStudent = [];

      teacherList.forEach((stdnt) => {
        classTeachers.push({ title: stdnt.classTeacher });
      });

      classTeachers = [
        ...new Map(classTeachers.map((item) => [item["title"], item])).values(),
      ];
      setStudents(teacherList);
      setStudentsByFilter(listOfStudent);
    }
  };

  const populateStudentDataByTeacherandGrade = (teacher) => {
 
    if (!teacher) {
      resetStudent();
    } else {
      listOfStudent = [];
      const filtedStd = allStudents.filter((stdData) => {
        return (
          stdData.classTeacher === teacher.title && stdData.grade === formData.grade && stdData.school === formData.school
        );
      });
      filtedStd.forEach((std) => {
        listOfStudent.push({ title: std.studentId });
      });
      // getting unique students list:
      listOfStudent = [
        ...new Map(listOfStudent.map((item) => [item["title"], item])).values(),
      ];
      
      setFormData(initialFormState);
      setFormData({
        ...initialFormState,
        school: formData.school,
        classTeacher: teacher.title,
        grade: formData.grade,
      });
      setStudentsByFilter(listOfStudent.sort((a, b) => parseInt(a.title) - parseInt(b.title)));
      renderImage("front-teeth", "");
      renderImage("top", "");
      renderImage("bottom", "");
      if(showOptLTR){
        renderImage("optout-letter", "");
      }

      setStudents(filtedStd);
    }
  };

  const DeleteOptOut = () => {
    setDeleteOptOut(!deleteOptOut);
    setShowPrompt(false);
    renderImage("optout-letter", "");
    setFormData({
      ...formData,
      optout: null,
    });
  };

  const OptLetterDropDown = () => {
    setShowOptLTR(!showOptLTR);
  }

  return (
    <div className={`CollectionApp ${lang}`}>
      {" "}
      {/* className={lang} */}
      {/* ----- Toaster code starts form here ----------- */}
      
      <Bootbox      
        show={showPrompt}
        type={"confirm"}  
        message={"Warning: Any photos taken will be deleted"}
        successLabel="Continue"
        cancelLabel="Cancel"
        onSuccess={()=>{inputFile.current.click();setShowPrompt(false);}}  
        onCancel={() => setShowPrompt(false)}  
        onClose={() => setShowPrompt(false)} 
      />
      
      <Row>
        <Col md={12} className="mb-2 toaster-popup">
          <Modal show={show} onHide={() => toggle()} className="toaster-popup">
            <Modal.Header
              closeButton
              className="modal-header-padding border-bottom-0"
              onHide={() => toggle()}
            ></Modal.Header>
            <Modal.Body className="text-center mt-n1">
              Student cannot be edited as the screening evaluation has been
              completed for this student
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
      {/* ----- Toaster code ends here ----------- */}
      <div className={classes.root}>
        <AppBar position="fixed" color="#fff">
          <Toolbar className={classes.flexToolbar}>
            <img class="logo_style" src={logo} alt="..." />
            <h5 className="logo-header" color="#fff">
              {t("School Dental Screening")}
            </h5>
            <nav role="navigation" class="desktop">
              <ul id="d-menu">
              </ul>
            </nav>
            <nav role="navigation" class="mobile">
              <div id="menuToggle">
                <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
                <ul id="menu">
                </ul>
              </div>
            </nav>
          </Toolbar>
        </AppBar>
      </div>
      {(!isPageLoaded) ? 
        <div className="content-container summary">
          <div className="leftArea">
            <div className="mb-3">
              <p><span class="align-text view-height-full"> Loading ...</span></p>
            </div>
          </div>
        </div>
        :
        <form onSubmit={handleSubmit}>
          {(!studentDataSubmitted) ? 
            <div className="mainContainer">
            <div className="welcome-msg">
              <h4 class="heading-font">
                {t("Welcome to 2022 - 2023 Dental Screening Program")}{" "}
              </h4>
            </div>
            <div className="form">
              <div className="formContainer">
                <div className="leftArea">
                  <QrReader
                    facingMode={selected}
                    delay={1000}
                    onError={handleError}
                    onScan={handleScan}
                    // chooseDeviceId={()=>selected}
                    style={{ width: "300px", margin: "0 auto" }}
                  />
                  {loadingScan && <p>Loading</p>}
                  {/* Show Screener Name */}
                  <div>
                    <p>
                      {t("School Nurse")}
                      <span class="required"></span>
                    </p>
                    <InputGroup className="mb-3">
                      <FormCntrl
                        // value={formData.screener}
                        value={screener}
                        //value = "Linda Johnson"
                        aria-label="screener"
                        aria-describedby="basic-addon1"
                        readOnly
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            screener: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </div>
                  <div>
                    <p>
                      {t("School District")}
                      <span class="required"></span>
                    </p>
                    <InputGroup className="mb-3">
                      <FormCntrl
                        value={formData.district}
                        aria-label="district"
                        aria-describedby="basic-addon1"
                        readOnly
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            district: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </div>
                  <div className="mb-3">
                    <p>
                      {t("School Name")}
                      <span class="required">*</span>
                    </p>
                    <Autocomplete
                      id="combo-box-demo"
                      options={schoolList}
                      getOptionLabel={(option) => option.title}
                      onChange={(event, newValue) =>
                        populateGradeBySchoolname(newValue)
                      }
                      inputValue={formData.school}
                      renderInput={(params) => (
                        <TextField {...params} label="" variant="outlined" />
                      )}
                    />
                  </div>

                  <div className="mb-3">
                    <p>
                      {t("Grade")}
                      <span class="required">*</span>{" "}
                    </p>
                    <Autocomplete
                      id="combo-box-demo"
                      options={gradeByTeacher}
                      getOptionLabel={(option) => option.title}
                      onChange={(event, newValue) => {
                        populateTeacherDataByGrade(newValue);
                      }}
                      inputValue={formData.grade}
                      renderInput={(params) => (
                        <TextField {...params} label="" variant="outlined" />
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <p>
                      {t("School Teacher")}
                      <span class="required">*</span>
                    </p>
                    <Autocomplete
                      id="combo-box-demo"
                      options={classTeachers}
                      getOptionLabel={(option) => option.title}
                      onChange={(event, newValue) => {
                        populateStudentDataByTeacherandGrade(newValue);
                      }}
                      inputValue={formData.classTeacher}
                      renderInput={(params) => (
                        <TextField {...params} label="" variant="outlined" />
                      )}
                    />
                  </div>
                  {/* ************ */}
                  <div className="mb-3">
                    <p>
                      {t("Student List")}
                      <span class="required">*</span>
                    </p>
                    <Autocomplete
                      id="combo-box-demo"
                      options={studentsByFilter}
                      getOptionLabel={(option) => option.title}
                      onChange={(event, newValue) => {
                        populateStudentDataList(newValue);
                      }}
                      inputValue={formData.studentId}                      
                      renderInput={(params) => (
                        <TextField {...params} label="" variant="outlined" />
                      )}
                    />
                  </div>
                  <div>
                    <p>
                      {t("First 3 Letters of Student's Legal First Name")}
                      <span class="required">*</span>
                    </p>
                    <InputGroup className="mb-3">
                      <FormCntrl
                        value={formData.firstName}
                        aria-label="firstName"
                        aria-describedby="basic-addon1"
                        maxlength="3"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            firstName: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </div>
                  <div className="mb-3">
                    <p>
                      {t("Gender")}
                      <span class="required">*</span>
                    </p>
                    <Dropdown
                      value={formData.gender}
                      onSelect={(e) => {
                        setFormData({
                          ...formData,
                          gender: e,
                        });
                      }}
                    >
                      <Dropdown.Toggle id="dropdown-basic">
                        {formData.gender}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="Male">
                          {t("Male")}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="Female">
                          {t("Female")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div>
                    <p>
                      {t("Parent/Guardian Email")}
                      <sup>1</sup>
                    </p>
                    <InputGroup className="mb-3">
                      <FormCntrl
                        placeholder="Email"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        type="email"
                        value={formData.guardianEmail}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            guardianEmail: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </div>
                  <div>
                    <p>
                      {t("Parent/Guardian Email 2")}
                      <sup>1</sup>
                    </p>
                    <InputGroup className="mb-3">
                      <FormCntrl
                        placeholder="Email"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        type="email"
                        value={formData.guardianEmail2}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            guardianEmail2: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </div>
                  <div className="mb-3">
                    <p>
                      {t("Are you experiencing any dental pain?")}
                      <span class="required">*</span>
                    </p>
                    <Dropdown
                      value={(formData.dentalPain === "Yes") ? "Yes" : "No"}
                      onSelect={(e) => {
                        setFormData({
                          ...formData,
                          dentalPain: e,
                        });
                      }}
                    >
                      <Dropdown.Toggle id="dropdown-basic">
                        {formData.dentalPain}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="Yes">
                          {t("Yes")}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="No">
                          {t("No")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="econsentmsg">
                    <span align="left">
                      <sup>1</sup>
                      {t(
                        "By providing email address you consent to receive emails with information such as screening results Kansas Medicaid information and other oral care education material"
                      )}
                    </span>
                  </div>
                  <div className="rightArea"></div>
                </div>
              </div>
              {formData.optoutReason === "NA" ||
              formData.optoutReason === undefined ? (
                <div className="photos-section">
                  <h6 className="BasicDetails">{t("PHOTOS")}</h6>
                  <h6 align="left" className="PhotosHeading">
                    {t(
                      "Please have your student in good lighting and take the pictures as shown"
                    )}{" "}
                    {". "}
                    {t("You can refer to this")}
                    <a
                      href="https://www.youtube.com/watch?v=ZRb-4HpAE9Y"
                      target="_blank"
                    >
                      {t(" Video")}
                    </a>{" "}
                    {t("on how to take the best photos for screening")}
                    {"."}
                  </h6>{" "}
                  <p></p>
                  {/* onChangeFrontTeethimage */}
                  <div className="uploadPictures">
                    <div class="up-title">{t("Front Teeth Demo Image")} </div>
                    <div class="up-image">
                      <img
                        className="image-placeholder"
                        src={DemoFrontTeethImg}
                        alt="..."
                      />
                    </div>

                    <div class="up-info">
                      <input
                        id="home-file-input-frontImage"
                        type="file"
                        className="input-file"
                        onChange={onChangeFrontTeethimage}
                        disabled={DisableImgFields(formData)}
                        // disabled={!formData.studentId}
                      />
                      
                      <label
                        id="front-teeth"
                        className="image-input-label"
                        htmlFor="home-file-input-frontImage"
                      >
                        {t("+ Click Here to Add Front Teeth Photo")}
                      </label>
                    </div>
                  </div>
                  {/* onChangetopimage */}
                  <div className="uploadPictures">
                    <div class="up-title">{t("Top Teeth Demo Image")} </div>
                    <div class="up-image">
                      <img
                        className="image-placeholder"
                        src={DemoTopImg}
                        alt="..."
                      />
                    </div>
                    <div class="up-info">
                      <input
                        id="home-file-input-top"
                        type="file"
                        class="input-file"
                        onChange={onChangetopimage}
                        disabled={DisableImgFields(formData)}
                      />
                      <label
                        id="top"
                        className="image-input-label"
                        htmlFor="home-file-input-top"
                      >
                        {t("+ Click Here to Add Top Teeth Photo")}
                        {".\n \n"}
                        {t(
                          "If you have trouble seeing back teeth, please use a small mirror"
                        )}
                      </label>
                    </div>
                  </div>
                  {/* onChangebottomimage */}
                  <div className="uploadPictures">
                    <div class="up-title">
                      {t("Bottom Teeth Demo Image")} {"  "}
                    </div>
                    <div class="up-image">
                      <img
                        className="image-placeholder"
                        src={DemoBottomImg}
                        alt="..."
                      />
                    </div>
                    <div class="up-info">
                      <input
                        id="home-file-input-bottom"
                        type="file"
                        class="input-file"
                        onChange={onChangebottomimage}
                        disabled={DisableImgFields(formData)}
                      />
                      <label
                        id="bottom"
                        className="image-input-label"
                        htmlFor="home-file-input-bottom"
                      >
                        {t("+ Click Here to Add Bottom Teeth Photo")}
                      </label>
                    </div>
                  </div>
                  {/* dentist optout letter */}
                  <div className="uploadPictures">
                    <div class="up-title" onClick={()=> OptLetterDropDown()}>
                      {t("Dentist optout letter")} {"  "} {showOptLTR ?  <MdKeyboardArrowUp style={{cursor:"pointer"}} size={25} /> : <MdKeyboardArrowDown style={{cursor:"pointer"}} size={25} /> }
                    </div>
                    {showOptLTR &&
                    <>
                    {/* From Here */}
                    <div class="up-image">
                      <img
                        className="image-placeholder"
                        src={DemoOptOutLetter}
                        alt="..."
                      />
                    </div>
                    <div class="up-info">
                      <input
                        id="home-file-input-optout-letter"
                        type="button"
                        class="input-file"
                        // onChange={onChangeOptoutLetter}
                        onClick={() => setShowPrompt(true)}
                        disabled={DisableImgFields(formData)}
                      />
                      <input type='file' id='file' onChange={onChangeOptoutLetter} ref={inputFile} style={{display: 'none'}}/>
                      <label
                        id="optout-letter"
                        className="image-input-label"
                        htmlFor="home-file-input-optout-letter"
                      >
                        {t("+ Click here to upload an image of dentist letter")}
                      </label>
                    </div>
                    <div>
                      {optOutButton && (
                        <>
                          {" "}
                          <input
                            type="checkbox"
                            checked={deleteOptOut}
                            onChange={() => DeleteOptOut()}
                          />{" "}
                          <span>Delete Letter</span>
                        </>
                      )}
                    </div>
                    </>
}
                    {/* To Here */}
                  </div>
                </div>
              ) : (
                ""
              )}
              {formData.optoutReason === "NA" ? (
                <h4>
                  <span class="required">
                    {" "}
                    {t("*Please select all the required fields")}
                  </span>
                  {isLoaded ? (

                    <>
                      <button
                        className="SubmitButton"
                        type="submit"
                        disabled={DisableSubmitButton(formData)}
                    //       disabled={
                    //   !(
                    //     formData.studentId &&
                    //     formData.firstname3letters &&
                    //     formData.grade &&
                    //     formData.gender
                    //   ) &&
                    //   !(
                    //     formData.optout ||
                    //     (formData.frontImage &&
                    //       formData.topImage &&
                    //       formData.bottomImage)

                    //   )
                    // }
                      >
                        {t("Submit Student*")}
                      </button>
                      {/* {optOutButton && <button className="SubmitButton" type="button" onClick={()=>DeleteOptOut()}>Delete Letter</button>} */}
                    </>

                  ) : (
                    <button className="SubmitButton" type="button" disabled>
                      {t("Please wait....")}
                    </button>
                  )}
                </h4>
              ) : (
                <h4>
                  <span class="required">
                    {" "}
                    {t("*Please select all the required fields")}
                  </span>
                  {isLoaded ? ( 
                  <button
                    className="SubmitButton"
                    type="submit"
                    disabled={DisableSubmitButton(formData)}
                  >
                    {t("Submit Student*")}
                  </button>
                  ) : (
                    <button className="SubmitButton" type="button" disabled>
                      {t("Please wait....")}
                    </button>
                  )}
                </h4>
              )}

              <h6 align="left">
                {"*"}{" "}
                {t(
                  "By submiting, you authorize dental professionals to review the submitted data for screening purposes"
                )}
                {"."}
              </h6>
              <br />
            </div>
            </div>
          :
            <div className="content-container summary">
              <div className="leftArea">
                <div className="mb-3">
                  <div class="alert alert-success" role="alert">
                    <h4 class="alert-heading">Students data has been submitted successfully!!</h4>
                  </div>
                </div>
              </div>
            </div>
          }
        </form>
      }
      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <p>
            {t(
              `Would you like to receive additional information on Kansas Medicaid:`
            )}{" "}
            {"  "}
          </p>
          <div>
            <input
              onClick={() => {
                setFormData({
                  ...formData,
                  okToReceiveMedicaidInfo: "Yes",
                });
                handleOpen();
              }}
              type="radio"
              name="question"
              id=""
              style={{ marginRight: "5px" }}
            />
            {t("Yes")} {"   "}
          </div>
          <div>
            <input
              onClick={() => {
                setFormData({
                  ...formData,
                  okToReceiveMedicaidInfo: "No",
                });
                handleOpen();
              }}
              type="radio"
              name="question"
              style={{ marginRight: "5px" }}
            />
            {t("No")}
          </div>
        </div>
      </Modal>
      <Modal
        open={confirmSubmitModel}
        onClose={handleConfirmSubmitModelClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <p>Thank you for participating in the virtual detal screening.</p>
          {formData.haveDentalInsurance === "Yes" && (
            <a
              href="https://www.kdheks.gov/hcf/Medicaid/eligibility_guidelines.html"
              target="_blank"
              alt="..."
            >
              Link to Kansas Medicaid
            </a>
          )}
          <br />
        </div>
      </Modal>
    </div>
  );
};
export default AutoCollectionApp;
