/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStudent = /* GraphQL */ `
  mutation CreateStudent(
    $input: CreateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    createStudent(input: $input, condition: $condition) {
      id
      code
      firstname3letters
      parentEmail
      location
      name
      gender
      district
      school
      grade
      haveDentalInsurance
      okToReceiveMedicaidInfo
      nonsmilingface
      frontTeeth
      leftimage
      rightimage
      topimage
      bottomimage
      untreatedDecay
      treatedDecay
      sealantsPresent
      treatmentRecommendationCode
      cannotEvaluate
      evalcomments
      evalStatus
      virtualScreeningExperience
      optout
      dentalPain
      screener
      optoutReason
      screeningDate
      createdAt
      updatedAt
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $input: UpdateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    updateStudent(input: $input, condition: $condition) {
      id
      code
      firstname3letters
      parentEmail
      location
      name
      gender
      district
      school
      grade
      haveDentalInsurance
      okToReceiveMedicaidInfo
      nonsmilingface
      frontTeeth
      leftimage
      rightimage
      topimage
      bottomimage
      untreatedDecay
      treatedDecay
      sealantsPresent
      treatmentRecommendationCode
      cannotEvaluate
      evalcomments
      evalStatus
      virtualScreeningExperience
      optout
      dentalPain
      screener
      optoutReason
      screeningDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent(
    $input: DeleteStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    deleteStudent(input: $input, condition: $condition) {
      id
      code
      firstname3letters
      parentEmail
      location
      name
      gender
      district
      school
      grade
      haveDentalInsurance
      okToReceiveMedicaidInfo
      nonsmilingface
      frontTeeth
      leftimage
      rightimage
      topimage
      bottomimage
      untreatedDecay
      treatedDecay
      sealantsPresent
      treatmentRecommendationCode
      cannotEvaluate
      evalcomments
      evalStatus
      virtualScreeningExperience
      optout
      dentalPain
      screener
      optoutReason
      screeningDate
      createdAt
      updatedAt
    }
  }
`;
export const createSchool = /* GraphQL */ `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      id
      name
      district
      createdAt
      updatedAt
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
      name
      district
      createdAt
      updatedAt
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      id
      name
      district
      createdAt
      updatedAt
    }
  }
`;
export const createDistrict = /* GraphQL */ `
  mutation CreateDistrict(
    $input: CreateDistrictInput!
    $condition: ModelDistrictConditionInput
  ) {
    createDistrict(input: $input, condition: $condition) {
      id
      name
      state
      createdAt
      updatedAt
    }
  }
`;
export const updateDistrict = /* GraphQL */ `
  mutation UpdateDistrict(
    $input: UpdateDistrictInput!
    $condition: ModelDistrictConditionInput
  ) {
    updateDistrict(input: $input, condition: $condition) {
      id
      name
      state
      createdAt
      updatedAt
    }
  }
`;
export const deleteDistrict = /* GraphQL */ `
  mutation DeleteDistrict(
    $input: DeleteDistrictInput!
    $condition: ModelDistrictConditionInput
  ) {
    deleteDistrict(input: $input, condition: $condition) {
      id
      name
      state
      createdAt
      updatedAt
    }
  }
`;
export const createK12Student = /* GraphQL */ `
  mutation CreateK12Student(
    $input: CreateK12StudentInput!
    $condition: ModelK12StudentConditionInput
  ) {
    createK12Student(input: $input, condition: $condition) {
      id
      location
      district
      school
      studentid
      firstname3letters
      gender
      grade
      parentEmail
      name
      haveDentalInsurance
      okToReceiveMedicaidInfo
      dentalPain
      optout
      optoutReason
      optoutDocImage
      nonsmilingface
      frontTeeth
      leftimage
      rightimage
      topimage
      bottomimage
      virtualScreeningExperience
      untreatedDecay
      treatedDecay
      sealantsPresent
      treatmentRecommendationCode
      cannotEvaluate
      evalcomments
      evalStatus
      screener
      screeningType
      screeningDate
      evaluator
      evalType
      evalDate
      createdAt
      updatedAt
    }
  }
`;
export const updateK12Student = /* GraphQL */ `
  mutation UpdateK12Student(
    $input: UpdateK12StudentInput!
    $condition: ModelK12StudentConditionInput
  ) {
    updateK12Student(input: $input, condition: $condition) {
      id
      location
      district
      school
      studentid
      firstname3letters
      gender
      grade
      parentEmail
      name
      haveDentalInsurance
      okToReceiveMedicaidInfo
      dentalPain
      optout
      optoutReason
      optoutDocImage
      nonsmilingface
      frontTeeth
      leftimage
      rightimage
      topimage
      bottomimage
      virtualScreeningExperience
      untreatedDecay
      treatedDecay
      sealantsPresent
      treatmentRecommendationCode
      cannotEvaluate
      evalcomments
      evalStatus
      screener
      screeningType
      screeningDate
      evaluator
      evalType
      evalDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteK12Student = /* GraphQL */ `
  mutation DeleteK12Student(
    $input: DeleteK12StudentInput!
    $condition: ModelK12StudentConditionInput
  ) {
    deleteK12Student(input: $input, condition: $condition) {
      id
      location
      district
      school
      studentid
      firstname3letters
      gender
      grade
      parentEmail
      name
      haveDentalInsurance
      okToReceiveMedicaidInfo
      dentalPain
      optout
      optoutReason
      optoutDocImage
      nonsmilingface
      frontTeeth
      leftimage
      rightimage
      topimage
      bottomimage
      virtualScreeningExperience
      untreatedDecay
      treatedDecay
      sealantsPresent
      treatmentRecommendationCode
      cannotEvaluate
      evalcomments
      evalStatus
      screener
      screeningType
      screeningDate
      evaluator
      evalType
      evalDate
      createdAt
      updatedAt
    }
  }
`;
export const createK12school = /* GraphQL */ `
  mutation CreateK12school(
    $input: CreateK12schoolInput!
    $condition: ModelK12schoolConditionInput
  ) {
    createK12school(input: $input, condition: $condition) {
      id
      name
      nurse
      state
      district
      createdAt
      updatedAt
    }
  }
`;
export const updateK12school = /* GraphQL */ `
  mutation UpdateK12school(
    $input: UpdateK12schoolInput!
    $condition: ModelK12schoolConditionInput
  ) {
    updateK12school(input: $input, condition: $condition) {
      id
      name
      nurse
      state
      district
      createdAt
      updatedAt
    }
  }
`;
export const deleteK12school = /* GraphQL */ `
  mutation DeleteK12school(
    $input: DeleteK12schoolInput!
    $condition: ModelK12schoolConditionInput
  ) {
    deleteK12school(input: $input, condition: $condition) {
      id
      name
      nurse
      state
      district
      createdAt
      updatedAt
    }
  }
`;
export const createK12StudentIds = /* GraphQL */ `
  mutation CreateK12StudentIds(
    $input: CreateK12StudentIdsInput!
    $condition: ModelK12StudentIdsConditionInput
  ) {
    createK12StudentIds(input: $input, condition: $condition) {
      id
      studentID
      firstname3letters
      createdAt
      updatedAt
    }
  }
`;
export const updateK12StudentIds = /* GraphQL */ `
  mutation UpdateK12StudentIds(
    $input: UpdateK12StudentIdsInput!
    $condition: ModelK12StudentIdsConditionInput
  ) {
    updateK12StudentIds(input: $input, condition: $condition) {
      id
      studentID
      firstname3letters
      createdAt
      updatedAt
    }
  }
`;
export const deleteK12StudentIds = /* GraphQL */ `
  mutation DeleteK12StudentIds(
    $input: DeleteK12StudentIdsInput!
    $condition: ModelK12StudentIdsConditionInput
  ) {
    deleteK12StudentIds(input: $input, condition: $condition) {
      id
      studentID
      firstname3letters
      createdAt
      updatedAt
    }
  }
`;
export const createKSK12Student = /* GraphQL */ `
  mutation CreateKSK12Student(
    $input: CreateKSK12StudentInput!
    $condition: ModelKSK12StudentConditionInput
  ) {
    createKSK12Student(input: $input, condition: $condition) {
      id
      district
      school
      nurseId
      nurseName
      nurseEmail
      screeningDate
      classTeacher
      grade
      studentId
      firstName
      gender
      guardianEmail
      guardianEmail2
      dentalPain
      frontImage
      topImage
      bottomImage
      untreatedDecay
      treatedDecay
      sealantsPresent
      treatmentRecommendationCode
      cannotEvaluate
      evalcomments
      evalStatus
      evaluator
      evalType
      evalDate
      optout
      qrcode
      createdAt
      updatedAt
    }
  }
`;
export const updateKSK12Student = /* GraphQL */ `
  mutation UpdateKSK12Student(
    $input: UpdateKSK12StudentInput!
    $condition: ModelKSK12StudentConditionInput
  ) {
    updateKSK12Student(input: $input, condition: $condition) {
      id
      district
      school
      nurseId
      nurseName
      nurseEmail
      screeningDate
      classTeacher
      grade
      studentId
      firstName
      gender
      guardianEmail
      guardianEmail2
      dentalPain
      frontImage
      topImage
      bottomImage
      untreatedDecay
      treatedDecay
      sealantsPresent
      treatmentRecommendationCode
      cannotEvaluate
      evalcomments
      evalStatus
      evaluator
      evalType
      evalDate
      optout
      qrcode
      createdAt
      updatedAt
    }
  }
`;
export const deleteKSK12Student = /* GraphQL */ `
  mutation DeleteKSK12Student(
    $input: DeleteKSK12StudentInput!
    $condition: ModelKSK12StudentConditionInput
  ) {
    deleteKSK12Student(input: $input, condition: $condition) {
      id
      district
      school
      nurseId
      nurseName
      nurseEmail
      screeningDate
      classTeacher
      grade
      studentId
      firstName
      gender
      guardianEmail
      guardianEmail2
      dentalPain
      frontImage
      topImage
      bottomImage
      untreatedDecay
      treatedDecay
      sealantsPresent
      treatmentRecommendationCode
      cannotEvaluate
      evalcomments
      evalStatus
      evaluator
      evalType
      evalDate
      optout
      qrcode
      createdAt
      updatedAt
    }
  }
`;
