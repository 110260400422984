import React, { useState, useEffect } from "react";
import {BrowserRouter as Router, Route, Redirect} from "react-router-dom";
import "./App.css";
import { Auth } from "aws-amplify";
import AutoCollectionApp from "./AutoCollection";

function App() {
  const DATA_COLLECTION_GROUP = "GuadalupeScreen";
  const DATA_EVALUATOR_GROUP = "GuadalupeEvaluation";
  const [userType, setUserType] = useState("");
  const DATA_MANUALSCREENING_GROUP = "GuadalupeManual";

  return (
    <div className="App">
      <Router>
          <Route path="/auto-collection" component={AutoCollectionApp} />
           <Redirect to="/auto-collection" />
      </Router>
    </div>
  );
}
// export default withAuthenticator(App);
export default App;
